<template>
  <div>
    <v-container fluid class='Container_Nivel2_Notas'>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-text-field
            v-model="pesquisaTabela"
            append-outer-icon="search"
            @click:append-outer="buscar"
            v-on:keyup.enter="buscar"
            :label="`${$tc('label.pesquisar_sellin', 1)}`"
            class="Barra_Pesquisa mx-4"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>

        <v-container fluid grid-list-md class="Container_Tabela py-3">
          <v-row>
            <v-col cols="12">
              <v-data-table
                id="tabela_notas"
                :headers="headers"
                :items="listaNotasConsolidado"
                :options.sync="pagination"
                :server-items-length="totalPage"
                @click:row="detalharConsolidado"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                }">
                <template v-slot:item.cod_fornecedor="{ item }">
                  {{ item.codFornecedor }}
                </template>
                <template v-slot:item.nome_fornecedor="{ item }">
                  {{ item.nomeFornecedor }}
                </template>
                <template v-slot:item.cod_linha="{ item }">
                  {{ item.codLinha }}
                </template>
                <template v-slot:item.nome_linha="{ item }">
                  {{ item.nomeLinha }}
                </template>
                <template v-slot:item.qtd_item="{ item }">
                  {{ item.qtdItem }}
                </template>
                <template v-slot:item.valor_bruto="{ item }">
                  {{ getMoney(item.valorBruto) }}
                </template>
                <template v-slot:item.valor_entrada="{ item }">
                  {{ getMoney(item.valorEntrada) }}
                </template>
                <template v-slot:item.valor_liquido="{ item }">
                  {{ getMoney(item.valorLiquido) }}
                </template>
                <template v-slot:item.acoes="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon class="mx-0" v-on="on" @click.stop="detalharConsolidado(item)">
                        <v-icon class="fa-sm">arrow_forward</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('label.detalhado') }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  getMoney,
} from '@/common/functions/helpers';

export default {
  name: 'NotasNivel2DadosListaConsolidado',
  components: {
  },
  props: {
    visao: String,
  },
  data() {
    return {
      notasResources: this.$api.notas(this.$resource),
      sellinDevolucaoResources: this.$api.notasSellinDevolucao(this.$resource),
      pesquisaTabela: '',
      esperarDigitar: false,
      listaNotasConsolidado: [],
      rowsPerPageItems: [10, 20, 30, 40, 50],
      pagination: {
        rowsPerPage: 10,
        page: 1,
        descending: true,
      },
      totalPage: 0,
      lastPagination: {},

      headers: [
        {
          text: this.$tc('label.quantidade_produto', 1), value: 'qtd_item', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        {
          text: this.$tc('label.valor_liquido', 1), value: 'valor_liquido', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        {
          text: this.$tc('label.valor_nf_ipi', 1), value: 'valor_entrada', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        {
          text: this.$tc('label.valor_total', 1), value: 'valor_bruto', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        {
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.lastPagination.page === null || typeof this.lastPagination.page === 'undefined') {
          this.lastPagination = pagination;
          return;
        }
        if (this.mesmaPagina(pagination, this.lastPagination) && this.mesmaOrdenacao(pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = pagination;
        this.buscar();
      },
      deep: true,
    },
  },
  computed: {
    isSellIn() {
      return this.visao === 'SELLIN';
    },

    isDevolucao() {
      return this.visao === 'DEVOLUCAO';
    },

    isSellOut() {
      return this.visao === 'SELLOUT';
    },
  },
  methods: {
    getMoney,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },

    mesmaOrdenacao(p1, p2) {
      return p1.sortBy[0] === p2.sortBy[0] && p1.sortDesc[0] === p2.sortDesc[0];
    },

    buscar() {
      const params = {
        filtro: this.pesquisaTabela,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      let promise = null;

      if (this.isSellOut) {
        promise = this.notasResources.listarNotasSellOutConsolidado(params);
      } else {
        promise = this.sellinDevolucaoResources.listarNotasSellInDevolucaoConsolidado(params);
      }

      promise.then((response) => {
        this.listaNotasConsolidado = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },

    pararEsperar() {
      setTimeout(() => {
        this.esperarDigitar = false;
      }, 2E2);
    },

    detalharConsolidado(item) {
      this.$emit('Notas__SelecionaItemNivel2', item);
    },

    addColunasSellinDevolucao() {
      this.headers.unshift(
        { text: this.$tc('label.competencia_sellin_consolidado', 1), value: 'competencia', sortable: true },
        { text: this.$tc('label.cod_fornecedor', 1), value: 'cod_fornecedor', sortable: true },
        { text: this.$tc('label.nome_fornecedor', 1), value: 'nome_fornecedor', sortable: true },
        { text: this.$tc('label.cod_linha_produto', 1), value: 'cod_linha', sortable: true },
        { text: this.$tc('label.nome_linha_produto', 1), value: 'nome_linha', sortable: true },
      );
    },

    addColunasSellOut() {
      this.headers.unshift(
        { text: this.$tc('label.competencia_sellin_consolidado', 1), value: 'competencia', sortable: true },
        { text: this.$tc('label.cod_regiao', 1), value: 'codRegiao', sortable: true },
        { text: this.$tc('label.nome_regiao', 1), value: 'nomRegiao', sortable: true },
        { text: this.$tc('label.cod_fornecedor', 1), value: 'codFornecedor', sortable: true },
        { text: this.$tc('label.nome_fornecedor', 1), value: 'nomeFornecedor', sortable: true },
      );
      this.headers = this.headers.filter((i) => i.value !== 'valorLiquido');
    },
  },
  beforeMount() {
  },
  mounted() {
    if (this.visao != null && this.visao.length) {
      if (this.isSellOut) {
        this.addColunasSellOut();
      } else {
        this.addColunasSellinDevolucao();
      }
    }
  },
};
</script>

<style>
.Container_Tabela tbody td:first-child,.Container_Tabela tbody td:not(:first-child),
  .Container_Tabela tbody th:first-child, .Container_Tabela tbody th:not(:first-child),
  .Container_Tabela thead td:first-child, .Container_Tabela thead td:not(:first-child),
  .Container_Tabela thead th:first-child, .Container_Tabela thead th:not(:first-child) {
  padding: 0 11px !important;
}
.Container_Tabela td {
  cursor: pointer;
}
.Container_Nivel2_Notas .Barra_Pesquisa {
  width: 33%;
}
</style>
