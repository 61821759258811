<template>
  <div>
    <v-container fluid px-0 py-2 class='Container_Tabela_Detalhado'>
      <v-row>
        <v-col cols="12" sm="6" md="3" class="mr-3 py-0">
          <v-text-field
            v-model="pesquisaTabela"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            class="mx-4"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon
              id="tabela-notas-botao-exportar"
              class="mt-4"
              v-on="on"
              @click="exportar">
              <v-icon>get_app</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.exportar') }}</span>
        </v-tooltip>

        <v-container fluid grid-list-md class="Container_Tabela_Detalhado py-3">
          <v-row>
            <v-col cols="12">
              <v-data-table
                id="tabela_notas"
                :headers="headers"
                :items="listaNotasDetalhado"
                :options.sync="pagination"
                :server-items-length="totalPage"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 25, 50],
                }"
                >
                <template v-slot:item.cod_fornecedor="{ item }">
                  {{ item.codFornecedor }}
                </template>
                <template v-slot:item.nome_fornecedor="{ item }">
                  {{ item.nomeFornecedor }}
                </template>
                <template v-slot:item.numero_nota_fiscal="{ item }">
                  {{ item.numeroNotaFiscal }}
                </template>
                <template v-slot:item.cod_linha="{ item }">
                  {{ item.codLinha }}
                </template>
                <template v-slot:item.nome_linha="{ item }">
                  {{ item.nomeLinha }}
                </template>
                <template v-slot:item.cod_familia="{ item }">
                  {{ item.codFamilia }}
                </template>
                <template v-slot:item.nome_familia="{ item }">
                  {{ item.nomeFamilia }}
                </template>
                <template v-slot:item.cod_kit="{ item }">
                  {{ item.codKit }}
                </template>
                <template v-slot:item.nome_kit="{ item }">
                  {{ item.nomeKit }}
                </template>
                <template v-slot:item.cod_produto="{ item }">
                  {{ item.codProduto }}
                </template>
                <template v-slot:item.nome_produto="{ item }">
                  {{ item.nomeProduto }}
                </template>
                <template v-slot:item.valor_bruto="{ item }">
                  {{ getMoney(item.valorBruto) }}
                </template>
                <template v-slot:item.valor_ipi="{ item }">
                  {{ getMoney(item.valorIpi) }}
                </template>
                <template v-slot:item.valor_icms="{ item }">
                  {{ getMoney(item.valorIcms) }}
                </template>
                <template v-slot:item.valor_pis_cofins="{ item }">
                  {{ getMoney(item.valorPisCofins) }}
                </template>
                <template v-slot:item.valor_liquido="{ item }">
                  {{ getMoney(item.valorLiquido) }}
                </template>
                <template v-slot:item.valor_entrada="{ item }">
                  {{ getMoney(item.valorEntrada) }}
                </template>
                <template v-slot:item.tipo_documento="{ item }">
                  {{ item.tipoDocumento }}
                </template>
                <template v-slot:item.cod_filial="{ item }">
                  {{ item.codFilial }}
                </template>
                <template v-slot:item.qtd_item="{ item }">
                  {{ item.qtdItem }}
                </template>
                <template v-slot:item.data_emissao="{ item }">
                  {{ item.dataEmissao }}
                </template>
                <template v-slot:item.data_conferencia="{ item }">
                  {{ item.dataConferencia }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  getMoney,
} from '@/common/functions/helpers';
import exportacao from '@/common/functions/exportacao';

export default {
  name: 'NotasNivel2DadosListaDetalhado',
  components: {
  },
  props: {
    visao: String,
    notaSelecionada: Object,
  },
  data() {
    return {
      notasResources: this.$api.notas(this.$resource),
      sellinDevolucaoResources: this.$api.notasSellinDevolucao(this.$resource),
      pesquisaTabela: '',
      listaNotasDetalhado: [],
      pagination: {
        rowsPerPage: 10,
        page: 1,
        descending: true,
      },
      totalPage: 0,
      esperarDigitar: false,

      headers: [],

      headersSellin: [
        {
          text: this.$tc('label.cod_fornecedor', 1), value: 'cod_fornecedor', sortable: true, cellClass: 'nowrap',
        },
        { text: this.$tc('label.nome_fornecedor', 1), value: 'nome_fornecedor', sortable: true },
        {
          text: this.$tc('label.documento', 1), value: 'numero_nota_fiscal', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        {
          text: this.$tc('label.cod_linha_produto', 1), value: 'cod_linha', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        { text: this.$tc('label.nome_linha_produto', 1), value: 'nome_linha', sortable: true },
        {
          text: this.$tc('label.cod_familia_produto', 1), value: 'cod_familia', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        { text: this.$tc('label.nome_familia_produto', 1), value: 'nome_familia', sortable: true },
        {
          text: this.$tc('label.cod_kit', 1), value: 'cod_kit', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        { text: this.$tc('label.nome_kit', 1), value: 'nome_kit', sortable: true },
        {
          text: this.$tc('label.cod_produto', 1), value: 'cod_produto', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        { text: this.$tc('label.nome_produto', 1), value: 'nome_produto', sortable: true },
        {
          text: this.$tc('label.quantidade_produto', 1), value: 'qtd_item', sortable: true, cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_liquido', 1), value: 'valor_liquido', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        {
          text: this.$tc('label.valor_nf_ipi', 1), value: 'valor_entrada', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        {
          text: this.$tc('label.valor_ipi', 1), value: 'valor_ipi', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        {
          text: this.$tc('label.valor_icms', 1), value: 'valor_icms', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        {
          text: this.$tc('label.valor_pis_cofins', 1), value: 'valor_pis_cofins', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        {
          text: this.$tc('label.valor_total', 1), value: 'valor_bruto', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        { text: this.$tc('label.tipo_documento', 1), value: 'tipo_documento', sortable: true },
        {
          text: this.$tc('label.filial', 1), value: 'cod_filial', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        {
          text: this.$tc('label.data_conferencia', 1), value: 'data_conferencia', sortable: true, cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.data_envio', 1), value: 'data_emissao', sortable: true, cellClass: 'nowrap', class: 'nowrap',
        },
        {
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
      ],
      headersSellout: [
        {
          text: this.$tc('label.data_emissao_lista_detalhado', 1), value: 'data_emissao', sortable: true, cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.cod_regiao', 1), value: 'codRegiao', sortable: true, cellClass: 'nowrap',
        },
        { text: this.$tc('label.nome_regiao', 1), value: 'nomRegiao', sortable: true },
        {
          text: this.$tc('label.cod_fornecedor', 1), value: 'codFornecedor', sortable: true, cellClass: 'nowrap',
        },
        { text: this.$tc('label.nome_fornecedor', 1), value: 'nomeFornecedor', sortable: true },
        {
          text: this.$tc('label.cod_produto', 1), value: 'cod_produto', sortable: true, cellClass: 'nowrap',
        },
        { text: this.$tc('label.nome_produto', 1), value: 'nome_produto', sortable: true },
        {
          text: this.$tc('label.quantidade_produto', 1), value: 'qtd_item', sortable: true, cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_bruto', 1), value: 'valor_bruto', sortable: true, cellClass: 'nowrap',
        },
      ],
    };
  },
  watch: {
    notaSelecionada: {
      handler() {
        this.resetarPaginacao();
        this.buscar();
      },
    },
    pagination: {
      handler() {
        this.buscar();
      },
      deep: true,
    },
    pesquisaTabela() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  computed: {
    isSellIn() {
      return this.visao === 'SELLIN';
    },

    isDevolucao() {
      return this.visao === 'DEVOLUCAO';
    },

    isSellOut() {
      return this.visao === 'SELLOUT';
    },
  },
  methods: {
    getMoney,

    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 1000);
    },

    buscar() {
      if (!this.notaSelecionada.anoMesConf) {
        return;
      }

      const params = {
        filtro: this.pesquisaTabela,
        idFornecedor: this.notaSelecionada.idFornecedor,
        idFilial: this.notaSelecionada.idFilial,
        idCliente: this.notaSelecionada.idCliente,
        idLinha: this.notaSelecionada.idLinha,
        anoMes: this.notaSelecionada.anoMesConf,
        codRegiao: this.notaSelecionada.codRegiao,
        codFornecedor: this.notaSelecionada.codFornecedor,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      let promise = null;

      if (this.isSellIn) {
        promise = this.sellinDevolucaoResources
          .listarNotasSellInDevolucaoDetalhado(params);
      } else {
        promise = this.notasResources.listarNotasSellOutDetalhado(params);
      }

      promise.then((response) => {
        this.listaNotasDetalhado = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
      }, (err) => {
        this.$error(this, err);
      });
    },
    exportar() {
      const params = {
        id_fornecedor: this.notaSelecionada.idFornecedor,
        id_filial: this.notaSelecionada.idFilial,
        id_cliente: this.notaSelecionada.idCliente,
        id_linha: this.notaSelecionada.idLinha,
        ano_mes: this.notaSelecionada.anoMesConf,
        cod_regiao: this.notaSelecionada.codRegiao,
        cod_fornecedor: this.notaSelecionada.codFornecedor,
      };

      if (this.pesquisaTabela && this.pesquisaTabela.length) {
        params.filtro = this.pesquisaTabela;
      }

      if (this.isSellIn) {
        exportacao.exportar(null, 'notas_sellin_devolucao_detalhado', this, params, 5000, 0);
      } else {
        exportacao.exportar(null, 'notas_sellout_detalhado', this, params, 5000, 0);
      }
    },
    resetarPaginacao() {
      this.totalPage = 0;
      this.pagination.rowsPerPage = 10;
      this.pagination.page = 1;
      this.pagination.descending = true;
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperarDigitar = false;
      }, 2E2);
    },
    montarColunasSellin() {
      this.headers = this.headersSellin;
    },
    montarColunasSellout() {
      this.headers = this.headersSellout;
    },
  },
  beforeMount() {
  },
  mounted() {
    if (this.isSellIn) {
      this.montarColunasSellin();
    } else if (this.isSellOut) {
      this.montarColunasSellout();
    }
    if (this.notaSelecionada.anoMesConf && this.visao && this.visao.length) {
      this.buscar();
    }
  },
};
</script>

<style>
.Container_Tabela_Detalhado table.v-table tbody td:first-child,.Container_Tabela_Detalhado  table.v-table tbody td:not(:first-child),
  .Container_Tabela_Detalhado table.v-table tbody th:first-child, .Container_Tabela_Detalhado table.v-table tbody th:not(:first-child),
  .Container_Tabela_Detalhado table.v-table thead td:first-child, .Container_Tabela_Detalhado table.v-table thead td:not(:first-child),
  .Container_Tabela_Detalhado table.v-table thead th:first-child, .Container_Tabela_Detalhado table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}

.Container_Tabela_Detalhado table {
  max-width: 250%;
  width: max-content;
}

</style>
